<template>
  <div class="app-container">
    <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="文化事业建设费申报表">
        <div class="right_box">
          <!-- <el-button type="primary" @click="download()" size="small" plain>导出</el-button> -->
          <el-button size="small" @click="printingNew()" type="success" plain>打印</el-button>
        </div>
        <culturalMain/>
      </el-tab-pane>
      <el-tab-pane label="增值税减免税申报明细表">
        <culturalFb1/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import culturalMain from './culturalMain.vue'
  import culturalFb1 from './culturalFb1.vue'
  import { report ,exportReport } from "@/api/printSet"

  export default {
    name: 'culturalTax',
    components: { culturalMain,culturalFb1 },
    data(){
      return{
        param:{
          comIds:[this.$store.getters['user/comInfo'].comId],
          period:this.$store.getters['user/comInfo'].period,
          bookNames:['gs_whsyjsfsbbygz']
        }
      } 
    },
    methods:{
      printingNew(){
        report(this.param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      },
      download(){
        exportReport(this.param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 180px !important;
  }
  .save_box{
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
  }
  .center{
    text-align: center;
    color: #333 !important;
  }
  .weight{
    font-size: 14px !important;
  }
  .left{
    text-align: left;
  }
  .right{
    text-align: right;
  }
  .left_k{
    text-indent: 41px;
  }
  .content{
    // width: 2000px;
    width: 100%;
    margin: 0 auto;
    border:1px solid #c2c2c2;
    border-bottom: none;
    border-right: none;
    margin-top: 10px;
    span{
      line-height: 26px;
      font-size: 14px;
    }
    tr{
      padding: 0;
      margin: 0;
    }
    td{
      border-right:1px solid #c2c2c2;
      border-bottom:1px solid #c2c2c2;
      line-height: 26px;
      padding:0 6px;
      color: #333;
      font-size: 14px;
    }
    input{
      width: 100%;
      line-height: 26px;
      padding: 0 6px;
      font-size: 14px;
      // text-align: right;
      // padding-right: 12px;
    }
  }
  .top_title3{
    width: 100%;
    margin: 0 auto;
    h4{
      font-size: 20px;
      color: #333;
      font-weight: 600;
      text-align: center;
    }
    h5{
      font-size: 16px;
      color: #333;
      font-weight: normal;
      text-align: center;
      margin-top: 10px;
    }
    .bottom_font{
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
      h6{
        float: left;
        width: 33.33%;
        font-size: 14px;
        color: #333;
        font-weight: normal;
      }
      h6:nth-child(1){
        padding-top: 10px;
      }
      h6:nth-child(2  ){
        padding-top: 10px;
      }
    }
  }

  .big_box4{
    width: 98%;
    // padding: 40px;
    background: #fff;
    // box-shadow: 0 2px 10px #ddd;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: auto;
  }
  .right_box {
    // float: right;
    width:25%;
    text-align: right;
    margin-bottom: 15px;
    margin-right: 16px;
    position: absolute;
    top: 0;
    right: 3px;
    
  }
  .right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
</style>